<template>
  <div ref="container" class="">
    <div>
      <div>
        <h1 class="font-medium text-lg lg:text-3xl">Carbon Reduced</h1>
        <span
          class="text-3xl lg:text-6xl font-bold lg:text-green-600 text-green-400"
        >
          {{ animatedCarbon }} Kg
        </span>
        <p class="text-gray-100 text-sm">
          {{ $t("carbonReduced") }}
        </p>
      </div>
    </div>
    <div>
      <div class="pt-10">
        <h1 class="font-medium text-lg lg:text-3xl">KMs Covered</h1>
        <span
          class="text-3xl lg:text-6xl font-bold lg:text-blue-600 text-blue-400"
        >
          {{ animatedKm }} Km
        </span>
        <p class="text-gray-100 text-sm">
          {{ $t("kmsCovered") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const carbonInc = 153.575177482686 / (24 * 60 * 60);
const kmInc = 1354.636830578512 / (24 * 60 * 60);

export default {
  data() {
    return {
      data: {
        km: 327882.113,
        carbon: 37165.19295081,
      },
      animatedCarbon: 0,
      animatedKm: 0,
      hasAnimated: false,
    };
  },
  methods: {
    animateNumber(property, target, duration) {
      const start = this[property];
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        this[property] = new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 0,
        }).format(Math.round(start + progress * (target - start)));

        if (progress < 1) {
          requestAnimationFrame(animate);
        }
      };

      requestAnimationFrame(animate);
    },
    startAnimation() {
      if (!this.hasAnimated) {
        this.hasAnimated = true;
        this.animateNumber(
          "animatedCarbon",
          Math.round(this.data.carbon),
          2000
        );
        this.animateNumber("animatedKm", Math.round(this.data.km), 2000);
      }
    },
    timer() {
      const randSec = 3;
      setTimeout(() => {
        this.data.carbon += randSec * carbonInc;
        this.data.km += randSec * kmInc;
        this.timer();
      }, randSec * 1000);
    },
  },
  created() {
    const secDiff = Math.floor(
      (new Date() - Date.parse("21 Jun 2024 23:00:00")) / 1000
    );
    this.data.carbon += carbonInc * secDiff;
    this.data.km += kmInc * secDiff;
    this.timer();
  },
  mounted() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.startAnimation();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(this.$refs.container);
  },
};
</script>

<style scoped>
/* Tambahkan animasi atau transisi CSS jika diperlukan */
</style>
